.home {
	.owl-stage-outer {

		.hover-border {
			border: 1px solid white;
			margin-bottom: 3px;
			box-shadow: 3px 2px 3px white;

			&:hover {
				border: 1px solid $divider-color;
				box-shadow: 3px 2px 3px #c1c1c1;
				width: calc(100% + 1px);
				padding-right: 16px;
			}
		}
	}

	.box_banner {
		margin-bottom: 10px;
		position: relative;
		padding: 0 30px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			overflow: hidden;
			padding: 0;
			height: 160px !important;

			li {
				float: left;
				border: 1px solid $divider-color;
				margin: 0 5px;
				text-align: center;

				img {
					display: inline-block;
				}
			}
		}

		.btn-prev {
			background: url('img/owl-left.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			left: 0;
			top: 58px;
			cursor: pointer;
		}

		.btn-next {
			background: url('img/owl-right.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			right: 0;
			top: 58px;
			cursor: pointer;
		}

		.caroufredsel_wrapper {
			width: 100% !important;
		}
	}

	#box_product_slide {
		margin-top: 10px;

		.tab-list {
			margin: 0;
			padding: 0;
			float: left;
			width: 100%;
			border-bottom: 1px solid $brand-1-color;

			li {
				list-style: none;
				float: left;
				background-color: transparent;
				color: $text-color;
				padding: 7px 15px;
				cursor: pointer;
				text-transform: uppercase;

				@media screen and (max-width: $tablet) {
					width: 50%;
					padding: 7px 0px;
					font-size: 14px;
					text-align: center;
				}

				@media screen and (max-width: 340px) {
					font-size: 13px;
				}

				&.active {
					background-color: black;
					color: white;
				}

				a {
					color: white;
				}

				&:last-child {
					float: right;
					background-color: transparent;
					padding: 7px 10px;
					text-transform: none;

					.fa {
						color: $text-color;
						margin-left: 5px;
					}

					a {
						color: $brand-3-color;
					}
				}

				&.view-all {
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
			}
		}

		.tab-content {
			display: none;
		}
	}

	// Danh mục sản phẩm nổi bật trên trang chủ cùng sản phẩm
	.category_feature {
		&:nth-child(2){
			.header_category{
				&:before{
					background-color: $brand-1-color;
				}
				.header_cate{
					background-color: $brand-1-color;
				}
			}
		}
		&:nth-child(3){
			.header_category{
				&:before{
					background-color: $brand-2-color;
				}
				.header_cate{
					background-color: $brand-2-color;
				}
			}
		}
		&:nth-child(4){
			.header_category{
				&:before{
					background-color: $brand-3-color;
				}
				.header_cate{
					background-color: $brand-3-color;
				}
			}
		}
		&:nth-child(5){
			.header_category{
				&:before{
					background-color: $brand-4-color;
				}
				.header_cate{
					background-color: $brand-4-color;
				}
			}
		}
		&:nth-child(6){
			.header_category{
				&:before{
					background-color: $brand-5-color;
				}
				.header_cate{
					background-color: $brand-5-color;
				}
			}
		}
		&:nth-child(7){
			.header_category{
				&:before{
					background-color: $brand-6-color;
				}
				.header_cate{
					background-color: $brand-6-color;
				}
			}
		}
		.header_category{
			position: relative;
			&:before{
				content: "";
				position: absolute;
				width: 100%;
				bottom: 0;
				height: 2px;
				background-color: $brand-b-color;
			}
			.header_cate{
				padding: 0 15px;
				width: 200px;
				float: left;
				font-size: 16px;
				text-transform: uppercase;
				background-color: $brand-b-color;
				color: #FFF;
				a{
					color: #FFF;
					line-height: 30px;
					.fa{
						line-height: 30px;
					}
					img{
						-webkit-filter: grayscale(1) invert(1);
						filter: grayscale(1) invert(1);
					}
				}
				@media(max-width: 991px){
					height: 31px;
					width: 160px;
					padding: 0 5px;
					font-size: 16px;
					a{
						img{
							display: none;
						}
						line-height: 33px;
						.fa{
							line-height: 33px;
						}
					}
				}
			}
		}
		.des_category{
			.nav-tabs{
				width: 200px;
				float: left;
				min-height: 1px;
				overflow-x: auto;
				@media(min-width: 768px){
					max-height: 634px;
				}
				@media(max-width: 991px) and (min-width: 768px){
					width: 160px;
				}
				@media(max-width: 767px){
					width: 100%;
					border-bottom: 1px solid #ddd;
				}
			}
			/*.product_maker{
				width: 200px;
				float: left;
				min-height: 1px;
				overflow-x: auto;
				@media(min-width: 768px){
					max-height: 576px;
				}
				.nav-maker{
					padding: 10px 0 10px 30px;
					li>a{
						color: #555;
						padding: 10px 15px;
					}
					&.owl-product-maker{
						li{
							line-height: 40px;
						}
					}
				}
				@media(max-width: 991px) and (min-width: 768px){
					width: 160px;
				}
				@media(max-width: 767px){
					width: 100%;
					.nav-maker{
						padding: 0;
						border-bottom: 1px solid #ddd;
						>li{
							float: left;
						}
					}
				}
			}
			.product_banner{
				width: 23%;
				float: left;
				@media(max-width: 767px){
					display: none;
				}
			}*/
			.product_items{
				width: calc(100% - 200px);
				float: left;
				@media(max-width: 991px) and (min-width: 768px){
					width: calc(100% - 160px);
				}
				@media(max-width: 767px){
					width: 100%;
				}
				.owl-cate-product:before{
					content: "";
					position: absolute;
					background: #ddd;
					width: 1px;
					height: 100%;
					left: 0;
				}
			}
		}
		.nav-tabs{
			border-bottom: 0;
			width: calc(100% - 200px);
			float: left;
			white-space: nowrap;
			overflow-x: auto;
			min-height: 41px;
			@media(max-width: 991px){
				width: calc(100% - 160px);
				min-height: 31px;
			}
			li{
				margin-bottom: 0;
				float: left;
				display: block;
				width: 100%;
				line-height: 41px;
				
				@media(max-width: 991px){
					line-height: 31px;
					>a{
					    padding: 6px 0!important;
					}
				}
				>a{
					color: #555;
					padding-left: 0;
					padding-right: 0;
					margin: 0 20px;
					padding: 10px 0;
					border: 0;
					border-radius: 0;
				}
				&.active{
					a,a:focus,a:hover{
						color: $brand-b-color;
						background-color: transparent;
					}
				}
			}
		}

		.products {
			.title-name {
				font-size: 14px;

				a.name {
					height: 34px;
				}
			}
		}

		aside.box.category {
			margin-bottom: 0;
			border: 0;
			.nav-v{
				ul{
					padding: 0;
					li{
						float: left;
						a{
							padding: 7px 0;
							margin: 0 15px;
							text-transform: none;
						}
					}
				}
			}
			#tree {
				.nav {
					li {
						&:nth-child(n+6) {
							display: none;
						}

						a {
							font-size: 12px;
							padding: 2px 10px;
							text-transform: none;
						}
					}
				}
			}

			.show-more {
				font-size: 20px;

				.fa {
					font-size: 20px;
					color: $brand-1-color;
					cursor: pointer;
				}
			}
		}
	}
}

.home,
.list_product {
	.heading {
		border-bottom: 2px solid $brand-1-color;
		margin: 20px 0 5px 0;
		float: left;
		width: 100%;

		&.best-buy {
			@media screen and (max-width: $tablet) {
				margin-top: 0;
			}
		}
	}
}
.banner_pages{
	background-color: #eee;
	.item_banner{
		.thumb{
			position: relative;
			img{
				margin-left: auto;
				margin-right: auto;
			}
			&:hover{
				.des{
					opacity: 1;
				}
			}
			
			.des{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.65);
				display: flex;
				justify-content: center;
				align-items: flex-end;
				transition: all .6s ease .1s;
				opacity: 0;
				@media(max-width: 767px){
					opacity: 1;
				}
				.des_banner{
					margin-top: auto;
					margin-bottom: auto;
				}
				
				.p_cate{
					a{
						color: #fff;
						font-size: 20px;
						font-weight: 600;
						text-transform: uppercase;
						line-height: 30px;
						padding: 5px 0;
						border-bottom: 1px solid #FFF;
						border-top: 1px solid #FFF;
					}
				}
				.p_child{
					list-style-type: none;
					padding: 0;
					a{
						color: #FFF;
						font-size: 14px;
						line-height: 30px;
					}
				}	
			}
			.des_title{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #FFF;
				justify-content: center;
				align-items: flex-end;
				transition: all .6s ease .1s;
				.title{
					margin-top: 15px;
					margin-bottom: 30px;
					@media (max-width: 991px){
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}
#show_post_home{
	.item_post{
		.name{
			font-size: 16px;
			line-height: 22px;
			color: $brand-1-color;
		}
	}
}
.tab-content > .tab-pane {
    visibility: hidden;
    height: 0px;
    overflow:hidden;
}
.tab-content > .active {
    visibility: visible;
    height: auto;
    overflow: visible;
}
.view-product-home{
	color: #337ab7;
	padding: 10px 15px 0 15px;
}