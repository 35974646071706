.read {
	header.title {
		margin-bottom: 10px;
	}
}

.related {
	float: left;
	width: 100%;

	.title {
		font-weight: 600;
		text-transform: uppercase;
		padding: 10px 0;
		font-size: 15px;
		border-bottom: 1px solid #ddd;
	}

	#show_post_related {
		float: left;

		ul {
			float: left;
			padding: 0;
			margin: 10px 0;
			list-style: none;

			li {
				padding: 0;
				margin-bottom: 2px;
				float: left;
				width: 100%;

				.dot {
					background: url('img/icon.png') -204px -119px no-repeat;
					width: 15px;
					height: 15px;
					display: block;
					float: left;
				}

				a {
					float: left;
					width: calc(100% - 15px);
				}
			}
		}
	}
}

.post-info-content {
	div,
	img {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
			height: auto !important;
		}
	}
}

.detail_post {
	.description {
		float: left;
		width: 100%;
	}

	#tab-selector {
		margin-top: 15px !important;
		clear: both;
	}

	.contact-title {
		text-align: center;
		font-size: 17px;
		line-height: 35px;
		margin: 10px 0 0 0;
		background: black;
		color: white;
	}

	#ContactIndexForm {
		border: 1px solid $divider-color;
		padding: 10px 10px 0 10px;

		#ContactMessage {
			min-height: 100px;
		}
	}

	ul.tabs li {
		background-color: white !important;
		color: $text-color !important;

		&.active {
			border-color: $brand-1-color !important;
			border-bottom: none !important;
		}

		a {
			color: $text-color !important;
		}
	}

	ul.tabs li a:hover {
		background-color: white !important;
		color: $text-color !important;
	}

	.tab-product-des {
		display: block !important;
	}

	.tab_container {
		width: 100% !important;
		border: none !important;

		.tab_content {
			padding: 10px 0 0 0;
			float: left;
			width: 100%;

			img {
				max-width: 100%;
				height: auto !important;
			}

			&#tab_comment {
				.embed-responsive-16by9 {
					padding-bottom: 25%;
				}

			}
		}
	}

	#comments-gp {
		width: 100% !important;
		height: auto !important;

		iframe {
			width: 100% !important;
		}
	}

	.facebook_comment_icon {
		width: 15px;
		vertical-align: top;
		margin-top: 5px;
	}

	.google_comment_icon {
		width: 15px;
		vertical-align: top;
		margin-top: 5px;
	}
}


.c_post {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}

	.title {
		border-bottom: 2px solid $brand-1-color;
		line-height: 35px;
		height: 35px;
		margin-bottom: 10px;
	}
	.datetime {
		margin: 0 0 5px 0;

		.glyphicon {
			font-size: 12px;
			margin-right: 3px;
		}
	}

	&.onecol {
		border-bottom: 1px solid $divider-color;
		padding-bottom: 10px;
		margin-bottom: 10px;

		&:last-child {
			border-bottom: none;
		}

		.name {
			font-weight: normal;
			font-size: 22px;
		}
	}
}

.list_post {
	.c_post {
		.post_item{
			position: relative;
			.name{
				position: absolute;
				bottom: 10px;
				width: 100%;
				a{
					display: block;
					width: 90%;
					color: #FFF;
					background-color: rgba(17, 132, 67, 0.8);
					margin: 0px 5px;
					padding-left: 10px;
				}
			}
		}
		.item_post{
			.name{
				a{
					font-size: 24px;
					line-height: 36px;
					color: $brand-1-color;
					@media(max-width: 576px){
						font-size: 18px;
						line-height: 24px;
					}
				}
			}
		}
	}
}
.des_top{
	color: #888;
	span{
		float: left;
		padding-right: 15px;
		margin-right: 15px;
		border-right: 1px solid #888;
		
		&:last-child {
			border-right: none;
		}
	}
}
#show_post_home{
	.item{
		.name,.img_post,.img_post_left{
			color: $brand-b-color;
		}
	}
}