aside.filter{
	.title {
		border: none;
		background-color: $brand-1-color;
		color: white;
		padding: 10px;
		box-sizing: border-box;
		text-transform: uppercase;

		.fa {
			margin-right: 5px;
		}
	}
	ul.nav{
		border: 1px solid #ddd;
		padding-top: 10px;
		padding-bottom: 10px;
		overflow-x: auto;
		max-height: 300px;
		li {
			padding: 0 10px;
			list-style: none;

			.check-maker {
				display: inline-block;
				float: left;
				margin: 13px 15px 0 0;
			}

			.name {
				float: left;
				width: calc(100% - 35px);
				padding: 10px 0;
			}
			&.first h4{
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 10px;
			}
			label{
				font-weight: normal;
				margin-left: 10px;
			}
		}
	}
}
aside.filter-mobile{
	margin-top: 5px;
	ul.nav-tabs-filter{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		margin: 0;
		list-style-type: none;
		>li>a{
			color: #1c7eb6;
			padding: 5px 0;
		}
	}
	.tab-container{
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		ul.nav{
			li {
				&.first h4{
					text-transform: uppercase;
					font-weight: bold;
					margin-bottom: 10px;
				}
				label{
					font-weight: normal;
					margin-left: 10px;
				}
			}
		}
		.tab_content{
			position: relative;
			.close-full{
				position: absolute;
				right: 5px;
				background: none;
				border: none;
				top: 5px;
				color: #158ac3;
				cursor: pointer;
				z-index: 9999;
			}
			ul{
				padding-top: 10px;
			}
			&#tab_feature{
				ul{
					width: 100%;
					float: left;
				}
			}
		}
	}
}
aside.box {
	border:1px solid #d7d7d7;
	float: left;
	width: 100%;
	&.newsletter{
		background-color: $brand-header;
		border: none;
		float: left;
		width: 100%;
		.n_title{
			@media(min-width: 1200px){
				padding: 20px 0 20px 0;
				margin-right: 30px;
				float: left;
			}
			color: #FFF;
			padding-top: 10px;
			text-transform: uppercase;
			font-weight: 600;
			line-height: 34px;
		}
		.des{
			@media(min-width: 1200px){
				padding-top: 20px;
				float: left;
				#newsletter{
					.input-group{
						width: 365px;
					}
				}
			}
			a{
				font-weight: 600;
				color: red;
			}
			.facebook {
				margin: 4px 0;
				/*padding: 3px 5px 1px 7px;
				background-color: #FFF;
				border-radius: 3px;*/
				float: left;
			}
			.twitter {
				margin: 4px 0 4px 10px;
				/*padding: 3px 5px 1px 7px;
				background-color: #FFF;
				border-radius: 3px;*/
				float: left;
			}
			.google {
				margin: 4px 0 4px 10px;
				/*padding: 3px 0px 1px 2px;
				background-color: #FFF;
				border-radius: 3px;*/
				float: left;
			}
			.youtube {
				margin: 4px 0 4px 10px;
				/*padding: 3px 3px 1px 5px;
				background-color: #FFF;
				border-radius: 3px;*/
				float: left;
			}
		}
		#newsletter_result{
			font-style: italic;
			color: red;
		}
	}
	.title {
		border: none;
		background-color: $brand-1-color;
		color: white;
		padding: 10px;
		box-sizing: border-box;
		text-transform: uppercase;

		.fa {
			margin-right: 5px;
		}
	}

	&.support {
		.supporters {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 10px;

			> li {
				float: left;
				width: 100%;
				border-bottom: 1px solid #ddd;
				padding-bottom: 5px;
				margin-bottom: 5px;

				&:last-child {
					border-bottom: 0;
				}

				.supporter {
					list-style: none;
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;

					li {
						float: left;
						width: 100%;

						&.name {
							font-weight: bold;
						}

						&.phone {
							color: red;
						}

						&.email {
							color: green;
						}

						.glyphicon {
							margin-right: 10px;
							color: black;
						}
					}
				}
			}
		}

		.social {
			text-align: center;
			list-style: none;
			float: left;
			width: 100%;
			margin: 10px 0 0 0;
			padding: 0;

			li {
				display: inline-block;
				height: 27px;
				width: 27px;
				margin: 0;

				&.facebook {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -7px -5px;
					background-size: 154px;
				}

				&.k {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -34px -5px;
					background-size: 154px;
				}

				&.gmail {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -62px -5px;
					background-size: 154px;
				}

				&.gplus {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -91px -5px;
					background-size: 154px;
				}
			}
		}
	}

	&.s_post {
		.post-thumb-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				border-bottom: 1px solid #efefef;
				position: relative;

				a {
					width: 100%;
					float: left;
					height: 100%;
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
						}
					}

					.name {
						float: left;
						width: calc(100% - 90px);
					}
				}
			}
		}
	}

	&.likebox {
		.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
			width: 100% !important;
		}
	}

	&.adv {
		ul {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}

	&.category {
		border: 1px solid #cecece;

		#tree {
			> li {
				padding: 0;
			}
		}

		.show-more {
			text-align: center;
			float: left;
			width: 100%;
		}
	}

	&.maker,
	&.price {
		.nav {
			li {
				padding: 0 10px;
				list-style: none;

				.check-maker {
					display: inline-block;
					float: left;
					margin: 13px 15px 0 0;
				}

				.name {
					float: left;
					width: calc(100% - 35px);
					padding: 10px 0;
				}
			}
		}
	}

	&.price {
		a {
			&:hover {
				color: $text-color;
			}
		}
	}

	&.product-other-page {
		ul.nav{
			padding: 10px 0;
		}
		#product-nav-tree-othersite {
			display: block;
			border-radius: 0;
			white-space: nowrap;
			font-size: 15px;
			font-weight: bold;
			background-color: $brand-2-color;
			line-height: 39px;
			margin: 0;
			position: static;
			padding: 0;
			border: none;

			> a {	// Danh mục sản phẩm
				display: block;
				margin: 0;
				padding-left: 0;
				padding-right: 0;
				width: 232px;
				text-align: center;
				background-color: $brand-2-color;

				@media screen and (max-width: $tablet) {
					width: 100%;
				}
			}

			.fa {
				float: right;
				font-size: 17px;
				cursor: pointer;

				@media screen and (max-width: $tablet) {
					display: none;
				}
			}

			.caret-wrapper {
				float: right;
				padding: 2px 10px;
				cursor: pointer;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}

			> ul {
				border-radius: 0;
				position: static;
				margin: 0;
				border: none;
				padding: 0;

				> li {
					/*&:nth-child(n+8):not(.show-more) {
						@media screen and (min-width: $tablet + 1px) {
							display: none;
						}
					}

					&.showing-more {
						display: block !important;
					}*/
					> a {
						text-transform: uppercase;
						white-space: normal;
					}

					&.current {
						> a {
							color: $brand-2-color;
							font-weight: bold;
						}
					}
				}
			}

			li {
				width: 100%;
				line-height: 30px;
				padding: 0;
				border-bottom: 1px solid $divider-color;
				min-width: 260px;

				&:hover {
					> a {
						color: $brand-2-color;;
					}

					> ul {
						@media screen and (min-width: $tablet + 1px) {
							display: block;
						}
					}
				}

				&.child {
					padding: 0 10px 0 27px;
					list-style-type: circle;
					list-style-position: inside;
				}

				&:last-child {
					border-bottom: none;
				}

				a {
					color: $text-color;
					font-size: 13px;
					padding: 5px 22px;
					background-image: none;
					background-color: white;
					line-height: 22px;

					&:hover {
						color: $brand-2-color;
					}

					.fa {
						float: right;
						font-size: 20px;
						line-height: 22px;

						@media screen and (max-width: $tablet) {
							display: none;
						}
					}
				}

				> ul {
					display: none;
					position: static;
					margin: 0;
					padding: 0;
					float: left;
					box-shadow: none;
					border-left: none;
					border-right: none;
					border-bottom: none;
					border-top: 1px solid $divider-color;
					border-radius: 0;
					min-width: initial;
					position: absolute;
					top: 0;
					left: 100%;
					display: none;

					@media screen and (min-width: $tablet + 1px) {
						border: 1px solid $divider-color;
					}

					li {
						margin: 0;
						padding: 0;
						width: 100%;
						float: left;

						a {
							padding: 5px 15px;

							&:hover,
							&:focus {
								background-color: white;
								background-image: none;
								text-decoration: underline;
								color: $brand-2-color;
							}
						}
					}
				}
			}

			.submenu-caret-wrapper {
				display: inline-block;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}

				@media screen and (max-width: $tablet) {
					padding: 0px 10px !important;
					float: right;
					display: block;
				}

				.caret {
					margin: 0 0 0 10px;

					@media screen and (max-width: $tablet) {
						margin: 0;
						height: auto;
						line-height: inherit;
						padding: 0;
						display: inline-block;
					}
				}
			}

			.show-more {
				text-align: center;
				line-height: 0;

				.fa {
					color: $brand-1-color;
					float: none;
				}
			}
		}
	}
}

.block_title {
	border: none;

	h2 {
		background-color: $brand-1-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;

		.glyphicon {
			margin-right: 10px;
		}
	}
}

#tree {
	list-style: none;
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;

	.caret-wrapper {
		float: right;
		padding: 2px 10px;
		cursor: pointer;
		display: none;
	}

	> .nav {
		> li {
			&:hover {
				> a {
					color: $brand-2-color;;
				}
			}

			&.current {
				a {
					color: $brand-2-color;
					font-weight: bold;
				}
			}

			> a {
				&:hover {
					color: $brand-2-color;;
				}
			}
		}
	}

	.nav {
		li {
			float: left;
			width: 100%;
			line-height: 30px;
			padding: 0;
			border-bottom: 1px solid $divider-color;

			&.current {
				> ul {
					display: block
				}
			}

			&.child {
				padding: 0 10px 0 27px;
				list-style-type: circle;
				list-style-position: inside;
			}

			&:last-child {
				border-bottom: none;
			}

			a {
				color: $text-color;
				font-size: 13px;
				padding: 5px 10px;

				.fa {
					float: right;
					font-size: 20px;
					line-height: 30px;
				}
			}

			> ul {
				display: none;
				position: static;
				margin: 0;
				padding: 0;
				float: left;
				box-shadow: none;
				border-left: none;
				border-right: none;
				border-bottom: none;
				border-top: 1px solid $divider-color;
				border-radius: 0;
				min-width: initial;
				position: absolute;
				top: 0;
				left: 100%;

				@media screen and (min-width: $tablet + 1px) {
					border: 1px solid $divider-color;
				}

				li {
					margin: 0;
					padding: 0;
					width: 100%;
					float: left;

					a {
						padding: 5px 15px;

						&:hover,
						&:focus {
							background-color: white;
							background-image: none;
							text-decoration: underline;
							color: $brand-2-color;
						}
					}
				}
			}
		}
	}
}
.product-other-page{
	.nav{
		>li{
			>h2 >a{
				padding: 5px 15px;
				font-size: 14px;
				line-height: 22px;
			}
		}
		li{
			ul{
				list-style-type: square;
				padding-left: 60px;
				li{
					a{
						padding: 5px;
					}
				}
			}
			&.current{
				>h2 >a, >h4 >a{
					color: red;
				}
			}
		}
	}
}
.post_sidebar{
	.nav{
		>li{
			>h2 >a{
				padding: 5px 15px;
				font-size: 14px;
				line-height: 30px;
			}
		}
		li{
			ul{
				padding-left: 40px;
				li{
					a{
						padding: 5px;
						line-height: 30px;
					}
				}
			}
			&.current{
				>h2 >a, >h4 >a{
					color: red;
				}
			}
		}
	}
}

.ui-widget-content{
	background-color: #ccc;
	width: 100%;
}
.ui-widget-header{
	background-color: $brand-b-color;
}
.ui-slider-horizontal{
	height: 5px;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
	background: $brand-b-color;
	border: 0;
	
	&:hover{
		background: #ddd;
	}
}
.ui-slider-horizontal .ui-slider-handle{
	top: -6px;
}
.product-other-page,.post_sidebar{
	h2,h4{
		display: inline-block;
	}
	.fa{
		float: right;
		line-height: 30px;
		margin-right: 10px;
	}
}