#slideshow-right {
	padding-left: 0;
	margin-left: calc(25% - 15px);
	padding-right: 0;

	@media screen and (max-width: $tablet) {
		margin: 0;
	}

	#slideshow-wrapper {
		float: left;
		width: 100%;
		background-color: #eeeeee;
		margin-bottom: 10px;

		#slideshow {
			margin: 0;
		}

		.lSSlideOuter {
			.lslide {
				text-align: center;

				img {
					display: inline-block !important;
				}
			}
		}
	}
}


.lSSlideOuter {
	position: relative;

	.lSPager.lSpg {
		position: absolute;
		bottom: 15px;
		left: calc(50% - 15px);

		> li {
			a {
				/*background-color: $brand-2-color !important;*/
			}
		}

		> li.hover a,
		> li.active a {
			background-color: $brand-1-color !important;
		}
	}
}

.banner-under-slideshow {
	margin-bottom: 15px;

	@media screen and (max-width: $tablet + 1px) {
		display: none;
	}

	img {
		width: 33.3333%;
		float: left;

		&:nth-child(2) {
			width: calc(33.3333% - 10px);
			margin: 0 5px;
		}
	}
}
#slideshow-wrapper{
	position: relative;
	.menu_slideshow{
		position: absolute;
		width: 100%;
		height: 100%;
		
		div{
			height: 100%;
			.navbar-nav{
				width: 100%;
				height: 100%;
				padding-top: 0;
				background-color: #FFF;
				position: relative;
				z-index: 999;
				border: 1px solid #ddd;
				box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.4);
				overflow: hidden;
				li{
					width: 100%;
					a{
						color: #000;
						border-bottom: 1px dashed #ddd;
						.fa{
							font-size: 20px;
						}
					}
					&:hover{
						>a{
							background-color: $brand-new-color;
							color: #FFF;
							img{
							    -webkit-filter: grayscale(1) invert(1);
								filter: grayscale(1) invert(1);
							}
						}
					}
					.dropdown-menu{
						top: 0;
						left: 100%;
						background-color: #FFF;
						li{
							width: 100%;
							
							a{
								color: #000;
								padding: 5px 20px!important;
							}
							&:hover{
								background-color: $brand-1-color;
								>a{
									color: #FFF;
								}
							}
						}
					}
				}
			}
		}
	}
}
.menu_slideshow_mobile{
	ul.slideshow_mobile{
		margin-bottom: 0;
		list-style: none;
		padding: 10px 0;
		li{
			display:block;
			float: left;
			width: 25%;
			@media(max-width: 475px){
				width: 33.3333%;	
			}
			a{
			    height: 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				margin: 3px;
				border: 1px solid $brand-1-color;
			}
		}
	}
}