.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f0f0f0;
}

.dropdown-menu {
	margin: 0;
}

.form-control {
	border-color: $brand-header;
	&:focus {
		box-shadow: none;
		border-color: $brand-header;
	}
}

.btn-default {
	background: $brand-1-color;
	border: none;
	color: white;
	text-shadow: none;
	outline: none !important;

	&:hover,
	&:active,
	&:active:focus,
	&:focus {
		background: darken($brand-1-color, 5);
		border: none;
		color: white;
		text-shadow: none;
	}
}
.btn-custom {
	background: $brand-b-color;
	border: none;
	color: white;
	text-shadow: none;
	outline: none !important;

	&:hover,
	&:active,
	&:active:focus,
	&:focus {
		background: darken($brand-b-color, 5);
		border: none;
		color: white;
		text-shadow: none;
	}
}
@media (max-width: 767px){
	.container {
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		padding-left: 7.5px;
		padding-right: 7.5px
	}
	.row{
		margin-right: -7.5px;
		margin-left: -7.5px;
	}
	.breadcrumb{
		padding: 8px 7.5px;
	}
	.navbar-form {
		margin-right: -7.5px;
		margin-left: -7.5px;
	}
	.display_pr_mobile{
		.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
			padding-left: 5px;
			padding-right: 5px
		}
		&.row{
			margin-right: -5px;
			margin-left: -5px;
		}
	}
}
@media (max-width: 475px){
	.hidden-ex {
		display: none!important;
	}
}
.visible-es{
	display: none;
}
@media (max-width: 576px){
	.hidden-es {
		display: none!important;
	}
	.visible-es{
		display: block!important;
	}
}
@media (max-width: 475px){
	.hidden-ex {
		display: none!important;
	}
}

@media (min-width:1200px){
	.auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@media (min-width:992px) and (max-width:1199px){
	.auto-clear .col-md-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-md-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-md-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-md-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@media (min-width:768px) and (max-width:991px){
	.auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@media (max-width:767px){
	.auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-xs-6:nth-child(odd){clear:left;}
}