.shoping_cart {
	table {
		margin: 0;
	}

	.content_cart {
		.thumb {
			float: left;
		}

		.infos {
			float: left;
			margin-left: 10px;
			width: calc(100% - 90px);

			@media screen and (max-width: $tablet) {
				width: 100%;
				margin: 10px 0 0 0;
			}
		}
	}

	.payment {
		p {
			margin: 0;
		}
	}
}
.payment_methods{
	.order_border{
		padding: 5px 10px;
		border: 1px solid #ddd;
		
		&:first-child{
			border-bottom: none;
		}
	}
	#transfer_des{
		border: 1px solid #ddd;
		padding: 10px;
	}
}