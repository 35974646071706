#header_top {
	float: left;
	width: 100%;
	background-color: $brand-top-color;
	font-size: 12px;
	z-index: 9999;
	a{
		font-size: 12px;
	}

	&.has-adv-bottom {
		@media screen and (max-width: $tablet) {
			margin-top: 55px;
		}
	}

	.left-menu {
		float: left;
		margin: 0;
		padding: 0;

		li {
			float: left;
			list-style: none;
			border-right: 1px solid $divider-color;

			&:last-child {
				border-right: none;
			}

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			a {
				line-height: 30px;
				padding: 0 10px;
				font-size: 13px;
				color: $gray-text-color;
			}
		}
	}

	.slogan {
		float: left;
		margin-left: 0;
		font-size: 13px;
		color: $gray-text-color;
		width: 315px;

		@media screen and (max-width: 1200px) {
			display: none;
		}

		.freeship {
			text-align: center;
			line-height: 30px;
			margin: 0;
			float: left;
		}
	}

	.right-menu {
		margin: 0;
		padding: 0;
		float: right;

		@media screen and (max-width: $tablet + 1px) {
			display: none;
		}

		> li {
			list-style: none;
			float: left;
			border-left: 1px solid $divider-color;

			&:first-child {
				border-left: none;
			}

			.icon-flag {
				background-position: -8px -224px;
				width: 20px;
				height: 15px;
				float: left;
				margin: 8px 10px;
			}

			.hotline {
				color: red;
				font-weight: bold;
				line-height: 30px;
				font-size: 15px;
				margin-right: 10px;

				i {
					margin-right: 5px;
				}

				a {
					color: red;
				}
			}

			> a {
				line-height: 30px;
				padding: 0 10px;
				font-size: 13px;
				color: $gray-text-color;
				cursor: pointer;
			}

			.dropdown {
				.dropdown-toggle {
					background: transparent !important;
					border: none;
					box-shadow: none;
					color: $gray-text-color;
					font-size: 13px;
				}

				.fa {
					margin-left: 5px;
				}

				.dropdown-menu {
					border-radius: 0;
					border-color: $divider-color;

					li {
						a {
							font-size: 13px;
							color: $gray-text-color;
							&:hover {
								background-image: none;
								background: transparent;
								color: $brand-2-color;
							}
						}
					}
				}
			}
		}
	}
	.social{
		padding-top: 5px;
		.facebook {
			background: url("img/icon/facebook.png");
			background-size: cover;
			width: 20px;
			height: 20px;
			float: left;
			margin-right: 5px;
		}
		.twitter {
			background: url("img/icon/twitter.png");
			background-size: cover;
			width: 20px;
			height: 20px;
			float: left;
			margin-right: 5px;
		}
		.google_plus {
			background: url("img/icon/google.png");
			background-size: cover;
			width: 20px;
			height: 20px;
			float: left;
			margin-right: 5px;
		}
		.youtube {
			background: url("img/icon/youtube.png");
			background-size: cover;
			width: 20px;
			height: 20px;
			float: left;
		}
		.fa{
			font-size: 12px;
			color: $brand-1-color;
		}
	}
	.phone{
		a{
			color: white;
			line-height: 30px;
			padding-right: 5px;
			float:left;
			>img{
				padding-top: 6px;
			}
		}
	}
	.navbar-toggle {
		float: right;
		margin: 0;
		margin-right: 15px;

		.icon-bar {
			background-color: white;
			width: 15px;
			height: 1px;
		}
	}
	.navbar-nav>li>a {
		padding-top: 5px;
		padding-bottom: 5px;
		color: #fff;
	}
}

#header {
	padding: 10px 0 10px 0;
	background-color: $brand-header;
	float: left;
	width: 100%;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,.4);
    position: relative;
	@media screen and (max-width: 767px) {
		float: left;
		width: 100%;
		padding: 0;
	}

	.logo {
		img {
			max-height: 55px;
			margin-top: 5px;
			margin-bottom: 5px;
			@media screen and (max-width: $tablet) {
				max-height: 50px;
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}
	.header-tags{
		font-size: 12px;
		text-align: justify;
		margin: 0;
		height: 20px;
		&:after{
			content: "";
			width: 100%;
			display: inline-block;
		}
		li{
			display: inline-block;
		}
		a{
			color: #FFF;
		}
	}
	.searchbox-wrapper {
		text-align: right;
		margin: 13px 0 0 0;

		@media screen and (max-width: $tablet) {
			position: relative;
			margin-top: 0 !important;
		}

		.banners {
			list-style: none;
			float: left;
			margin: 5px 0 0 0;
			padding: 0;

			@media screen and (max-width: 1200px) {
				display: none;
			}

			li {
				float: left;
				background-image: url('img/header_banner_icons.png');
				background-size: 75px;
				background-repeat: no-repeat;
				padding-left: 42px;

				&:nth-child(1) {
					background-position: 0 -87px;
					width: 230px;
				}

				&:nth-child(2) {
					background-position: 0 -42px;
					width: 232px;
				}

				&:nth-child(3) {
					background-position: 0 0;
					width: 200px;
				}

				.big-label {
					text-transform: uppercase;
					font-weight: bold;
					text-align: left;
					margin: 0;
					letter-spacing: 0.1px;
				}

				.small-label {
					text-align: left;
					font-size: 11px;
					line-height: 15px;
					margin: 0;
				}
			}
		}
	}

	.navbar-toggle {
		margin: 10px 0 0 0;

		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		@media screen and (max-width: $tablet) {
			float: right;
		}

		.menu-text {
			margin-top: 4px;
			display: block;
		}

		.glyphicon-search {
			margin-top: 5px;
			color: $brand-1-color;
		}
	}

	.searchbox-wrapper {
		margin-top: 7px;
		float: left;
		width: 100%;

		#form-filter-basic {
			float: left;
			position: relative;
			box-shadow: none !important;
			@media screen and (min-width: 768px) {
				padding: 0;
			}
			#srch-term {
				border: 1px solid $divider-color;
				width: 100%;
				box-shadow: none;

				@media screen and (max-width: $tablet) {
					width: 100%;
				}
			}

			.filter-category-select {
				border: 1px solid $divider-color;
				border-right: 0;

				@media screen and (max-width: $tablet) {
					display: none;
				}

				#FilterProductCategory {
					-webkit-appearance: none;
					-moz-appearance: none;
					border: none;
					height: 32px;
					box-shadow: none;
					background: url(img/select-down-arrow.png) no-repeat;
					background-size: 10px;
					background-position: 195px 14px;
					width: 212px;
				}
			}

			.btn-search {
				background-color: $brand-b-color;
				float: left;
				box-shadow: none;
				border: none;
				text-shadow: none;
				padding: 6px;
				.icon_search {
					background-position: -45px -224px;
					width: 18px;
					height: 18px;
					display: block;
					margin: 1px 2px;
					float: left;
				}
			}
		}

		.trending {
			.title {
				color: $brand-5-color;
			}

			.vline {
				color: $text-color;
			}

			a {
				color: $brand-3-color;
			}

			a,
			span {
				display: inline-block;
				float: left;
			}
		}
	}

	.cart {
		line-height: 32px;
		margin-top: 27px;
		float: right;
		border: 2px solid #FFF;
		border-radius: 5px;
		width: 130px;
		@media(min-width: 768px) and (max-width: 991px){
			width: 100px;
		}
		@media screen and (max-width: $tablet) {
			width: 100px;
			margin: 0;
			margin-top: 20px;
		}

		.input-group-btn {
			background-color: $brand-1-color;
			padding: 0 10px;

			.icon_cart {
				background-position: -76px -223px;
				width: 19px;
				height: 18px;
				display: block;
			}

		}

		.sp {
			@media screen and (max-width: 991px) {
				display: none;	
			}
		}

		.link_cart {
			color: #FFF;
			line-height: 32px;
			border-left: none;

			@media screen and (max-width: $tablet) {
				float: left;
				padding: 0 10px;
			}
		}
	}
}
.nav-top{
	>ul{
	    list-style: none;
		margin: 0;
		padding: 0;
		line-height: 26px;
		height: 26px;
		color: #FFF;

		>li{
		    display: inline-block;
			color: #000;
			a{
				color: #FFF;
			}
			/*@media(max-width:767px){
				color: #000;
				a{
					color: #000;
				}
			}*/
			&.t_hightlight{
				a{
					padding: 3px 5px!important;
					background-color: transparent;
					color: $brand-1-color;
					border-radius: 5px;
					border: 1px solid $brand-1-color;
					font-weight: 600;
				}
			}
			&.social_top{
				.fa{
					font-size: 20px;
					line-height: 26px;
					margin-right: 5px;
				}
			}
		}
	}
	&.pull-right{
		>ul >li{
			a{
				padding: 0 10px;
				
			}
			&:first-child{
				>a{
					padding-left: 0;
				}
			}
			&:last-child{
				>a{
					padding-right: 0;
				}
			}
		}
	}
}
.fixed_top {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
}
.top_hotline{
	margin-top: 13px;
	a{
		color: #FFF;
		font-size: 12px;
		.fa{
		    font-size: 20px;
			padding: 7px;
			width: 35px;
			height: 35px;
			border: 2px solid #FFF;
			border-radius: 50%;
		}
		&:hover,&:focus{
			color: #FFF;
		}
	}
}
.nav-hightlight{
	@media(max-width: 767px){
		margin: 14px 5px 0 0;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			line-height: 26px;
			height: 26px;
			color: #fff;
			>li{
				display: inline-block;
				color: #fff;
				a{
					font-size: 14px;
					padding: 8px 9px;
					font-weight: 600;
					background-color: transparent;
					color: $brand-1-color;
					border-radius: 5px;
					border: 1px solid $brand-1-color;
				}
			}
		}
	}
}
.promotion-icon{
	margin-top: -2px;
}