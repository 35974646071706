#sub-footer-1 {
	float: left;
	width: 100%;
	background-color: #f5f5f5;
	padding: 15px 0;
	margin-top: 20px;

	.services {
		.icon-1 {
			background-position: -8px -256px;
			width: 49px;
			height: 49px;
			display: block;
			float: left;
		}

		.icon-2 {
			background-position: -73px -253px;
			width: 48px;
			height: 46px;
			display: block;
			float: left;
		}

		.icon-3 {
			background-position: -139px -256px;
			width: 49px;
			height: 45px;
			display: block;
			float: left;
		}

		.icon-4 {
			background-position: -203px -255px;
			width: 49px;
			height: 48px;
			display: block;
			float: left;
		}

		.text {
			float: left;
			width: calc(100% - 60px);
			margin-left: 10px;
			margin-bottom: 0;

			@media screen and (max-width: $tablet) {
				margin-bottom: 20px;
			}

			.big {
				font-weight: bold;
				width: 100%;
				display: block;
				text-transform: uppercase;
				font-size: 14px;
			}

			.small {
				font-size: 13px;
			}

			.hotline {
				color: $brand-2-color;
			}
		}
	}
}
#sub-footer-2,#sub-footer-mobile {
	float: left;
	width: 100%;
	a{
	}
	&.line-top {
		/*border-top: 1px solid $brand-1-color;*/
	}

	h4 {
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 8px;
		padding-bottom: 3px;
	}
	
	p{
		line-height: 22px;
	}

	table {
		width: 100%!important;
		max-width: 100%!important;
	}

	.support-wrapper,
	.social-wrapper {
		@media screen and (max-width: $tablet) {
			width: 100%;
			float: left;
		}
	}

	.supporters {
		padding: 0;
		margin: 0 0 10px 0;
		float: left;
		width: 100%;

		@media screen and (max-width: $tablet) {
			margin: 0 8px;
			width: auto;
		}

		li {
			list-style: none;
			float: left;
			width: 100%;
			margin-bottom: 15px;
			
			.fa {
				margin-right: 15px;
				font-size: 30px;
				float: left;
				border-radius: 50%;
				background-color: $brand-1-color;
				color: #FFF;
				&.fa-phone{
					padding: 5px 8px;
					width: 40px;
					height: 40px;
				}
				&.fa-mobile{
					padding: 5px 14px;
					width: 40px;
					height: 40px;
				}
			}

			.phone {
				color: $brand-5-color;
			}
			
			a{
				color: red;
				font-weight: 600;
				font-size: 18px;
				span{
					color: #444;
					font-size: 12px;
					font-weight: nomal;
				}
			}
			&.f_hotline{
				@media(max-width: 767px){
					width: 50%;
				}
				@media(max-width: 420px){
					.fa{
						float: none;
						display: block;
					}
				}
			}
		}
	}

	.socials {
		@media screen and (max-width: $tablet) {
			margin: 0 8px;
			width: auto;
		}

		.fa {
			font-size: 25px;
			margin-right: 7px;
		}
	}
	.service-wrapper{
		border: 1px dashed $brand-1-color;
		background-color: #f5f5f5;
		padding: 10px;
		li{
			width: 100%;
			float: left;
			padding: 7px 0;
			@media(min-width: 768px){
				width: 50%;
			}
			.fa{
				color: $brand-1-color;
			}
		}
	}
	.support-customer{
		padding: 0;
		margin: 0 0 10px 0;
		float: left;
		width: 100%;
		>li{
			>a{
				color: #555;
				padding: 7px 0;
			}
		}
	}
}
#fanpage_facebook{
	overflow: hidden;
}
#sub-footer-mobile{
	padding: 10px 0;
	background-color: #444;
	color: #FFF;
	a{
		color: #FFF;
	}
}
#footer {
	padding: 0;
	font-size: 15px;
	float: left;
	width: 100%;

	address {
		margin: 20px 0 15px 0;

		@media screen and (max-width: $tablet) {
			margin: 15px 0;
		}
	}

	table {
		tbody {
			tr {
				td {
					width: 25%;
					float: left;
					display: block;
					padding-right: 30px;

					@media screen and (max-width: $tablet) {
						width: 50%;
						padding: 0 5px;
						float: left;
					}

					&:nth-child(3) {
						@media screen and (max-width: $tablet) {
							clear: left;
						}
					}

					img {
						@media screen and (max-width: $tablet) {
							max-width: 100%;
							height: auto !important;
						}
					}

					h4 {
						margin: 5px 0 10px 0;
						height: 30px;
						font-weight: bold;
					}

					&:nth-child(4) {
						p {
							@media screen and (max-width: $tablet) {
								word-break: break-all;
							}
						}
					}
				}
			}
		}
	}

	p {
		font-size: 13px;
		line-height: 23px;
		margin: 0 0 15px 0;
	}
}

#copyright {
	float: left;
	width: 100%;
	background-color: $brand-top-color;
	color: #bfbfbf;
	text-align: center;

	.footer-tags {
		margin-top: 10px;
		margin-bottom: 10px;

		a {
			color: white;
		}
	}
}
#sub-footer{
	.footer-branch{
		padding-right: 15px;
		padding-left: 15px;
		background-color: #ddd;
		h1,h4{
			color: $brand-1-color;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			font-size: 14px;
			margin-bottom: 20px;
		}
		.info{
			line-height: 24px;
			.name{
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 10px;
			}
			.view-map{
				span{
					color: #337ab7;
				}
			}
			.fa{
				width: 15px;
				text-align: center;
				margin-right: 5px;
			}
		}
	}
}