#cfacebook{
	position:fixed;
	bottom:0px;
	right: 65px;
	z-index:999999999999999;
	width:250px;
	height:auto;

	@media screen and (max-width: $tablet) {
		right: 0;
	}
}
#cfacebook .fchat{
	float:left;
	width:100%;
	display: none;
	height:270px;
	overflow:hidden;
	background-color:#fff;
}
#cfacebook .fchat .fb-page{
	float:left;
}
#cfacebook a.chat_fb{
	color:#fff;
	text-decoration:none;
	height:35px;
	line-height:35px;
	background-color: #0090d2;
	z-index:9999999;
	font-size:17px;
	border-radius: 4px 4px 0 0;
}
#cfacebook a.chat_fb:hover{
	text-decoration:none;
}
#cfacebook a.chat_fb .icon_facebook {
		margin-top: 8px;
}