$brand-1-color: #e70008;
$brand-new-color: #f9910f;
$brand-top-color: #081a24;
$brand-header: #0e2835;
$brand-product: #e1eaef;
$brand-b-color: #e7731c;

$brand-2-color: #00a9c2;
$brand-3-color: #e3007b;
$brand-4-color: #00b034;
$brand-5-color: #7700d2;
$brand-6-color: #00a29c;

$divider-color: #ddd;
$hard-divider-color: #b4b4b4;
$text-color: #333;
$gray-text-color: #656565;
$right-boxes-space: 30px;
$mobile: 576px;
$tablet: 768px;

* {
  outline: none;
}

@import 'old_frontend';
@import 'override_bootstrap';
@import 'flash_message';
@import 'global';
@import 'nav';
@import 'header';
@import 'footer';
@import 'home';
@import 'product';
@import 'contact';
@import 'category';
@import 'post';
@import 'counter';
@import 'comment';
@import 'information';
@import 'smooth_product';
@import 'sidebar';
@import 'slideshow';
@import 'breadcrumb';
@import 'gallery';
@import 'video';
@import 'document';
@import 'order';
@import 'fchat';
