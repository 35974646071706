.home {
	.product-grid-item {
		margin: 10px 0 0 0;
	}
}

.list_product {
	.heading{
		margin-top: 0;

		.head-label {
			padding-right: 0;
		}
	}

	.thumb {
		text-align: center;
	}

	.product-grid-item {
		.name {
			a {
				font-weight: bold;
			}
		}

		.filter-name {
			font-weight: normal;
		}
	}

	#product-wrapper {
		clear: both;
	}

	#show-more {
		border: 1px solid $brand-1-color;
		color: $brand-1-color;
		background-image: none;
		margin-top: 30px;
		text-transform: uppercase;
		font-weight: 600;
		border-radius: 4px;
		padding: 7px 100px;
		background-color: white;

		.fa {
			margin-left: 5px;
		}
	}
}

.product-grid-item {
	margin-bottom: 0;
	z-index: 10;

	.name {
		padding: 0;
		margin: 10px 0 10px 0;
		text-transform: uppercase;
		color: $text-color;
		font-weight: bold;

		a {
			text-transform: uppercase;
			color: $text-color;
		}
	}

	.img-wrapper {
		display: inline-block;
		overflow: hidden;

		img {
			transition: all .8s ease-in-out;

			&:hover {
				transform:scale(1.05);
				-ms-transform:scale(1.05); /* IE 9 */
				-moz-transform:scale(1.05); //Firefox
				-webkit-transform:scale(1.05); /* Safari and Chrome */
				-o-transform:scale(1.05); /* Opera */
			}
		}
	}

	.infos {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		display: none;

		.detail {
			float: right;

			.glyphicon-chevron-right {
				color: $brand-2-color;
				font-size: 8px;
				vertical-align: top;
				margin-top: 5px;
			}
		}

		.contact-link {
			&:hover {
				color: #e32a2d;
			}
		}

		.filter-name {
			font-weight: bold;
		}
	}
}

.detail_product {
	header.name {

		h1 {
			text-transform: uppercase;
			font-size: 24px;
			font-weight: bold;
		}
	}

	.images {
		padding: 0;

		// Khu soi anh sản phẩm
		.sp-wrap {
			padding: 0;
			border: none !important;
			margin: 0;

			.sp-thumbs {
				a {
					margin-right: 10px;
				}
			}
		}
	}

	.info-section {
		.detail-infos {
			margin: 0;
			padding: 0;
			float: left;
			width: 100%;

			li {
				float: left;
				list-style: none;
				border-right: 1px solid $text-color;
				margin: 0 5px 5px 0;
				padding-right: 5px;
				line-height: 15px;

				&:last-child {
					border-right: none;
				}

				a {
					color: $brand-3-color;
					cursor: pointer;
				}
			}
		}

		.price {
			float: left;
			width: 100%;
			font-weight: 600;
			margin-bottom: 10px;
			.old-price {
				line-height: 28px;
				del {
					color: #999;
				}
			}

			.new-price {
				line-height: 24px;
				span {
					color: $brand-1-color;
					font-size: 22px;
				}
				@media(max-width: 991px){
					width: 100%;
				}
			}
			
			.discount-price{
				margin-left: 20px;
				color: #FFF;
				padding: 0 3px;
				background-color: $brand-1-color;
				border-radius: 5px;
				position: relative;
				&:after{
					position: absolute;
					top: calc(50% - 3px);
					left: -6px;
					width: 0;
					height: 0;
					display: block;
					content: " ";
					border-color: transparent;
					border-style: solid solid outset;
					pointer-events: none;
					z-index: 999;
					border-width: 3px;
					border-right-color: $brand-1-color;
				}
			}
		}

		.promotion-warranty {
			float: left;
			width: 100%;
			margin-bottom: 10px;

			.icon_tick {
				background-position: -111px -225px;
				width: 14px;
				height: 15px;
				margin: 2px 12px 0 0;
				display: block;
				float: left;
			}

			> p {
				&.promotion {
					color: $brand-5-color;
				}
			}
		}
		.promotion{
			background-color: #f4f4f4;
			float: left;
			width: 100%;
			padding: 15px;
			font-size: 16px;
			h4{
				font-weight: bold;
				color: $brand-1-color;
				margin-bottom: 10px;
				text-transform: uppercase;
				font-size: 16px;
			}
			.fa{
				color: green;
				margin-right: 15px;
			}
			a{
				color: #337ab7;
			}
		}
		.commit {
			background-color: #f4f4f4;
			float: left;
			width: 100%;
			padding: 5px 10px;
			margin-bottom: 10px;

			.title {
				color: $brand-1-color;
				font-weight: bold;
				text-transform: uppercase;
				margin-top: 10px;
				text-align: center;

				.site-name {
					color: red;
				}
			}

			.icon_tick {
				background-position: -111px -225px;
				width: 14px;
				height: 15px;
				margin: 2px 12px 0 0;
				display: block;
				float: left;
			}
			.fa{
				font-size: 24px;
				width: 30px;
				line-height: 30px;
				text-align: center;
				float: left;
			}
			span.des{
				float: left;
				font-size: 12px;
				padding-left: 7px;
				width: calc(100% - 30px);
			}
		}

		.group-button {
			float: left;
			width: 100%;
			margin-bottom: 15px;

			.btn-1 {
				background-color: $brand-b-color;
				border-radius: 4px;
				color: white;
				float: left;
				width: 48%;
				padding: 10px;
				cursor: pointer;
				
				>.title{
					text-transform: uppercase;
					font-weight: 600;
				}
				
				@media screen and (max-width: $tablet) {
					width: 100%;
				}

				.icon-tick {
					background-position: -239px -8px;
					width: 30px;
					height: 20px;
					display: block;
					float: left;
					margin: 10px 6px 10px 9px;
				}
			}

			.btn-2 {
				background-color: $brand-1-color;
				border-radius: 4px;
				color: white;
				float: right;
				width: 48%;
				padding: 10px;
				cursor: pointer;
				>.title{
					text-transform: uppercase;
					font-weight: 600;
				}
				i {
					font-size: 18px;
					margin: 0 10px;
					vertical-align: text-bottom;
				}

				@media screen and (max-width: $tablet) {
					width: 100%;
					margin: 10px 0;
					text-align: center;
				}

				.icon-cart {
					background-position: -279px -5px;
					width: 30px;
					height: 25px;
					display: block;
					float: left;
					margin: 6px 10px;
				}

				.icon-user {
					background-position: -363px -1px;
					width: 30px;
					height: 28px;
					display: block;
					float: left;
					margin: 6px 6px 6px 9px;
				}
			}

			.btn-3 {
				border-color: #3030ff;
				background-color: #3030ff;
				color: white;
				float: left;
				text-transform: uppercase;
				cursor: pointer;
				width: 33.33%;

				@media screen and (max-width: $tablet) {
					width: 100%;
					text-align: center;
				}

				.icon-phone {
					background-position: -323px -3px;
					width: 33px;
					height: 26px;
					display: block;
					float: left;
					margin: 7px 6px 7px 10px;
				}

				.title-hotline {
					float: left;
					width: calc(100% - 63px);
					margin: 0;
					font-size: 16px;
					text-align: center;
					line-height: 16px;

					.title {
						font-size: 7px;
						width: 100%;

						@media screen and (max-width: $tablet) {
							font-size: 11px;
							display: inline-block;
							margin-bottom: 3px;
						}
					}
				}
			}
		}

		.services {
			background-color: #f4f4f4;
			float: left;
			width: 100%;
			padding: 10px;
			margin: 0;

			li {
				float: left;
				list-style: none;
				width: 25%;

				@media screen and (max-width: $tablet) {
					width: 100%;
					margin-bottom: 10px;
				}
			}

			.icon-1 {
				background-position: -5px -171px;
				width: 34px;
				height: 31px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-2 {
				background-position: -48px -169px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-3 {
				background-position: -92px -170px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-4 {
				background-position: -221px -218px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 504px;
			}

			.text {
				float: left;
				width: calc(100% - 48px);
				margin-left: 10px;
				margin-bottom: 0;
			}
		}

		.tag {
			float: left;
			width: 100%;
			margin: 10px 0;
		}
	}

	.product_des {
		.nav-tabs {
			> li {
				text-transform: uppercase;
				border-radius: 0;

				&.active {
					> a {
						margin-bottom: 1px;
						border: 0!important;
						font-weight: bold;
						font-size: 15px;
						padding-left: 0;
					}
				}

				&:first-child {
					> a {
						border-left: 1px solid #d4d4d4;
					}
				}

				> a {
					border-radius: 0;
					margin-right: 0;
					padding: 7px 15px;
					font-size: 14px;
					border: 1px solid #d4d4d4;
					border-left: 0;

					&:hover {
						/*color: white;
						background-color: $brand-1-color;
						border: 1px solid $brand-1-color;
						*/
					}
				}
			}
		}
	}

	.related {
		float: left;
		width: 100%;
	}

	#mua-ngay {
		float: left;
		width: 100%;

		.order {
			.form {
				border-top: none;
				border: 1px solid $divider-color;
				padding: 10px;

				form {
					.form-group {
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}

			.show-cart {
				text-transform: initial;
				font-weight: normal;
				font-size: 13px;
			}
		}

		.call-hotline-btn {
			background: red !important;
		}

		#order_info_content {
			th {
				background-color: black;

				.title {
					text-align: center;
					text-transform: uppercase;
					font-weight: bold;
					line-height: 30px;
					color: white;
					margin: 0;
				}
			}

			td {
				border: none;
			}

			.product {
				padding: 10px 20px;
				display: block;

				.thumb {
					width: 120px;

					@media screen and (max-width: $tablet) {
						width: 100px;
					}

					img {
						width: 100px;

						@media screen and (max-width: $tablet) {
							width: 80px;
						}
					}
				}

				.name {
					font-size: 14px;
					margin: 0 0 5px 0;
				}

				.new {
					color: red;
					font-size: 16px;
				}
			}

		}
	}

	.owl-carousel {
		.owl-stage {
			@media screen and (min-width: $tablet + 1px) {
				transform: translate3d(0px, 0px, 0px) !important;
			}
		}

		.owl-nav {
			top: -7px;

			@media screen and (min-width: $tablet + 1px) {
				display: none !important;
			}

			.owl-prev {
				left: 0;
			}

			.owl-next {
				right: 0;
			}
		}
	}

	.order .order_info{
		width: 100%;
		border: 1px solid $divider-color;
		margin-right: 1px;
		font-size: 12px;
		overflow: hidden;
		border-bottom: none;
	}
	.order .order_info span.title{
		background: #333;
		color: #fff;
		font-weight: bold;
		display: block;
		text-align: center;
		font-size: 13px;
	}
	.order .order_info table{
		width: 100%;
	}
	.order .order_info table th{
		padding: 5px 0;
		text-align: left;
		background: #f5f5f5
	}
	.order .order_info table th{
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
		padding: 5px;
	}
	.order .order_info table .name{
		font-weight: bold;
	}
	.order .order_info table .status{
		color: green;
	}
	.order .order_info table .promotion{
		color: #ff0000;
		font-style: italic;
	}
	.order .order_info table .old{
		text-decoration: line-through;
		color: #555;
	}
	.order .order_info table .new{
		font-weight: bold;
	}
	.order .order_info table .discount{
		color: #ff0000;
		font-style: italic;
	}
	.order .order_info table .center{
		text-align: center;
	}

	.order .order_info table td{
		padding: 0 5px;
		border-bottom: 1px solid #eee;
		border-right: 1px solid #eee;
	}
	.order .order_info table th:last-of-type,
	.order .order_info table td:last-of-type{
		border-right: none;
	}
	.order .order_info table tr.total th{
		text-align: right;
		background: none;
	}
	.order .order_info table tr.total th,
	.order .order_info table tr.total td{
		border-top: 1px dotted #aaa;
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		color: #ff0000;
		white-space: nowrap;
		border: none;
	}

}

.list_product,
.filter_product {
	.hover-border {
		border: 1px solid white;
		margin-bottom: 3px;
		box-shadow: 2px 2px 3px white;
		padding-top: 10px;
		@media screen and (min-width: $tablet + 1px) {
			width: calc(33.3333% - 3px);
			margin-right: 3px;
		}

		&:hover {
			border: 1px solid $divider-color;
			box-shadow: 2px 2px 3px #c1c1c1;
		}
	}
}

.product-spec{
	.title{
		line-height: 36px;
		font-size: 15px;
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: 1px solid #ddd;
	}
	ul.nav{
		padding: 10px 0;
		li{
			line-height: 30px;
			border-bottom: 1px dashed #ddd;
			span{
				width: 170px;
				display: inline-block;
			}
		}
	}
}
.filter-mobile{
	.navbar-nav-filter-mobile{
		float: left;
		margin: 0;
		margin-bottom: 5px;
		width: 100%;
		li.dropdown{
			float: left;
			width: 50%;
			>a{
				padding-top: 6px;
				padding-bottom: 6px;
				text-align: center;
				background-color: #ddd;
				margin: 2px;
				color: #000!important;
			}
		}
		.open>a, .open>a:hover {
			background-color: #ddd;
		}
		li.dropdown:nth-child(even){
			.dropdown-menu{
				left: auto;
				right: 0;
			}
		}
		.dropdown-menu{
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 1000;
			float: left;
			min-width: 210px;
			padding: 5px 0;
			margin: 2px 0 0;
			font-size: 14px;
			text-align: left;
			list-style: none;
			background-color: #FFF;
			-webkit-background-clip: padding-box;
			background-clip: padding-box;
			border: 1px solid #ccc;
			border: 1px solid rgba(0,0,0,.15);
			border-radius: 4px;
			-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
			box-shadow: 0 6px 12px rgba(0,0,0,.175);
			li{
			    padding: 3px 10px;
				label{
					font-weight: normal;
				}
				>a{
					color: #000!important;
					padding: 0;
					line-height: 26px;
				}
			}
			&.dropdown-maker{
				li{
					float: left;
					width: 50%;
				}
			}
		}
	}
}