.breadcrumb {
	li {
		font-weight: bold;

		&:not(:first-child)::before {
			content: '>' !important;
			color: #333;
		}
		&:last-child{
			color: $brand-1-color;
		}
	}
}