#nav-menu {
	border-bottom: 1px solid $hard-divider-color;
	border-top: 1px solid $hard-divider-color;
	// box-shadow: 0 2px 3px #b4b4b4;

	@media screen and (max-width: $tablet) {
		float: left;
		width: 100%;
		padding: 0;
		border: none;
		box-shadow: none;
	}

	&.stick {
		background-color: white;

		@media screen and (min-width: $tablet + 1px) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10000;
		}
	}

	.navbar-header {
		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}
	}

	.navbar-collapse {
		@media screen and (max-width: $tablet) {
			border: none;
		}
	}
	.navbar {
		min-height: 0;

		@media screen and (max-width: $tablet) {
			float: left;
			width: 100%;
		}

		.navbar-nav {
			@media screen and (max-width: $tablet) {
				margin: 0 -15px;
			}

			> li {
				border-left: 1px solid $hard-divider-color;

				@media screen and (max-width: $tablet) {
					border-left: none;
					border-right: none;
					border-bottom: 1px solid $divider-color;
				}

				&:last-child {
					border-right: 1px solid $hard-divider-color;

					@media screen and (max-width: $tablet) {
						border-right: none;
					}
				}

				> a {
					text-transform: uppercase;
					text-align: right;
					padding: 10px;
					margin: 0 2px;
					color: black;

					@media screen and (max-width: $tablet) {
						margin: 0;
						text-align: left;
					}

					&:hover,
					&:focus {
						outline: none;
						background-color: white;
					}
				}
			}

			#product-nav-tree {
				border: none;
				display: block;
				border-radius: 0;
				white-space: nowrap;
				font-size: 15px;
				line-height: 39px;
				margin: 0;
				padding: 0 15px;
				border-left: 1px solid $hard-divider-color;

				@media screen and (max-width: $tablet) {
					width: 100%;
					padding: 0;
					border-bottom: 1px solid black;
					border-left: none;
				}

				&.desktop {
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}

				&.mobile {
					border-bottom: 1px solid $divider-color;;

					@media screen and (min-width: $tablet + 1px) {
						display: none;
					}

					> a {
						text-align: left;
						padding: 10px 15px;
						font-size: 13px;
					}

					> ul {
						margin: 0 15px;

						li:last-child {
							border-bottom: none;
						}
					}
				}

				> a {	// Danh mục sản phẩm
					display: block;
					margin: 0;
					padding-left: 0;
					padding-right: 0;
					width: 232px;
					text-align: center;
					background-color: white;
					white-space: normal;

					@media screen and (max-width: $tablet) {
						width: 100%;
					}
				}

				.fa {
					float: right;
					font-size: 17px;
					cursor: pointer;

					@media screen and (max-width: $tablet) {
						display: none;
					}
				}

				.caret-wrapper {
					float: right;
					padding: 2px 10px;
					cursor: pointer;

					@media screen and (min-width: $tablet + 1px) {
						display: none;
					}
				}

				> ul {
					border-radius: 0;
					padding: 0;

					> li {
						&:nth-child(n+8):not(.show-more) {
							@media screen and (min-width: $tablet + 1px) {
								display: none;
							}
						}

						&.showing-more {
							display: block !important;
						}

						> a {
							text-transform: uppercase;
							white-space: normal;
						}

						&.current {
							> a {
								color: $brand-2-color;
								font-weight: bold;
							}
						}
					}
				}

				li {
					width: 100%;
					line-height: 30px;
					padding: 0;
					border-bottom: 1px solid $divider-color;
					min-width: 260px;

					&:hover {
						> a {
							color: $brand-2-color;;
						}

						> ul {
							@media screen and (min-width: $tablet + 1px) {
								display: block;
							}
						}
					}

					&.child {
						padding: 0 10px 0 27px;
						list-style-type: circle;
						list-style-position: inside;
					}

					&:last-child {
						border-bottom: none;
					}

					a {
						color: $text-color;
						font-size: 13px;
						padding: 5px 22px;
						background-image: none;
						background-color: white;
						line-height: 22px;

						&:hover {
							color: $brand-2-color;
						}

						.fa {
							float: right;
							font-size: 20px;
							line-height: 22px;

							@media screen and (max-width: $tablet) {
								display: none;
							}
						}
					}

					> ul {
						position: static;
						margin: 0;
						padding: 0;
						float: left;
						box-shadow: none;
						border-left: none;
						border-right: none;
						border-bottom: none;
						border-top: 1px solid $divider-color;
						border-radius: 0;
						min-width: initial;
						top: 0;
						left: 100%;
						position: static;
						display: none;

						@media screen and (max-width: $tablet) {
							display: block;
						}

						@media screen and (min-width: $tablet + 1px) {
							position: absolute;
							border: 1px solid $divider-color;
						}

						li {
							margin: 0;
							padding: 0;
							width: 100%;
							float: left;

							a {
								padding: 5px 15px;

								@media screen and (max-width: $tablet) {
									padding: 5px 15px 5px 22px;
								}

								&:hover,
								&:focus {
									background-color: white;
									background-image: none;
									text-decoration: underline;
									color: $brand-2-color;
								}
							}
						}
					}
				}

				.show-more {
					text-align: center;
					line-height: 0;

					.fa {
						color: $brand-1-color;
						float: none;
					}
				}
			}
			.submenu-caret-wrapper {
				display: inline-block;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}

				@media screen and (max-width: $tablet) {
					padding: 0px 10px !important;
					float: right;
					display: block;
				}

				.caret {
					margin: 0 0 0 10px;

					@media screen and (max-width: $tablet) {
						margin: 0;
						height: auto;
						line-height: inherit;
						padding: 0;
						display: inline-block;
					}
				}
			}
			.menu_marker{
				@media (min-width: 1200px) {
					width: 500px;
					right: 0;
					left: unset;
					
					li{
						width: 33.333333%;
						float: left;
					}
				}
				@media (min-width: 768px) and (max-width: 1199px){
					width: 500px;
					left: 0;
					right: unset;
					li{
						width: 33.333333%;
						float: left;
					}
				}
			}
		}
	}
}
.header_maker{
	li{
		width: 11.111111%;
		float: left;
		
		@media screen and (max-width: 767px) {
			width: 33.333333%;
		}
		.thumb{
			img{
				border: 1px solid #555;
				border-radius: 50%;
			}
		}
		.name{
			margin-top: 10px;
		}
	}
}
.owl-product-marker{
	.item{
		.thumb{
			img{
				border: 1px solid #555;
				border-radius: 50%;
			}
		}
		.name{
			margin-top: 10px;
		}
	}
}
#product-nav-tree,
#product-nav-tree-slideshow {
	&.banner {
		li {
			> ul {
				width: 587px;
				left: calc(100% + 4px) !important;
				top: 35px !important;

				li {
					width: 50% !important;
					border: none !important;

					@media screen and (max-width: $tablet) {
						width: 100% !important;
					}

					a {
						font-size: 13px !important;
						color: $brand-2-color !important;

						@media screen and (max-width: $tablet) {
							color: $text-color !important;
						}

						&:hover {
							color: $text-color !important;
						}
					}
				}
			}
		}

		> ul {
			> li {
				position: static;

				.white-bg {
					position: absolute;
					top: 0;
					left: calc(100% + 3px) !important;
					width: 877px;
					height: 415px;
					background-color: white;
					display: none;
					border-bottom: 1px solid #bdbdbd;
					border-right: 1px solid #bdbdbd;
					border-left: 1px solid #bdbdbd;
				}

				&:hover {
					img {
						display: block;
						margin-top: 30px;
					}

					> .white-bg {
						display: block;
					}


					> .title-parent {
						display: block;
					}
				}

				.link-banner {
					padding: 0 !important;
				}

				ul {
					border: none !important;
				}

				img {
					position: absolute;
					top: 0;
					left: 855px;
					width: 270px;
					height: 339px;
					z-index: 10;
					display: none;
				}

				.title-parent {
					position: absolute;
					top: 0;
					left: calc(100% + 19px);
					z-index: 10000;
					text-transform: uppercase;
					font-weight: normal;
					border-bottom: 1px solid $divider-color;
					width: 560px;
					line-height: 29px;
					border-left: 2px solid $brand-2-color;
					padding-left: 10px;
					margin: 2px 0;
					display: none;
				}
			}
		}
	}
}
#nav-menu-mobile {
	@media (min-width: 768px){
		display: none;
	}
	.navbar-header{
		border-top: 1px solid #333;
		.navbar-toggle{
			float: left;
			.icon-bar{
				border: 1px solid #333;
			}
		}
	}
	border-bottom: 1px solid $hard-divider-color;
	border-top: 1px solid $hard-divider-color;

	&.stick {
		background-color: $brand_header;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 2999999999;
	}
	.navbar-nav {
		@media screen and (max-width: $tablet) {
			margin: 0;
		}
		>li.open{
			>ul.dropdown-menu{
				position: absolute!important;
			}
		}
		> li {
			width: 20%;
			display: block;
			float: left;
			min-height: 30px;
			@media screen and (max-width: $tablet) {
				border-left: none;
				border-right: none;
			}
			
			@media screen and (max-width: 420px) {
				font-size: 9px;
			}
			
			&:last-child {
				border-right: 1px solid $hard-divider-color;

				@media screen and (max-width: $tablet) {
					border-right: none;
				}
			}

			> a {
				text-align: center;
				padding: 0;
				margin: 0;
				color: #FFF;
				height: 45px;

				&:hover,
				&:focus {
					outline: none;
				}
				.fa{
					font-size: 36px;
					line-height: 45px;
					color: #FFF;
					&.fa-bars{
						color: #FFF;
					}
				}
				@media(max-width: 420px){
					font-size: 12px;
				}
			}
			ul.dropdown-menu{
				background-color: $brand_header;
				border: 1px solid #eee;
				border-radius: 0;
				>li{
					a{
						.fa{
							margin-right: 10px;
						}
					}
				}
			}
			>ul.dropdown-menu{
				bottom: 100%;
				top: auto;
				min-width: 210px;
			}
		}

		.submenu-caret-wrapper {
			display: inline-block;
			padding: 0 10px;
			margin-left: 10px;

			@media screen and (min-width: 768px) {
				display: none;
			}
			.caret {
				margin: 0 0 0 10px;

				@media screen and (max-width: $tablet) {
					margin: 0;
					height: auto;
					line-height: inherit;
					padding: 0;
					display: inline-block;
				}
			}
		}
		.menu_marker{
			@media (min-width: 1200px) {
				width: 500px;
				right: 0;
				left: unset;
				
				li{
					width: 33.333333%;
					float: left;
				}
			}
			@media (min-width: 768px) and (max-width: 1199px){
				width: 500px;
				left: 0;
				right: unset;
				li{
					width: 33.333333%;
					float: left;
				}
			}
		}
	}
	.fchat{
		display: none;
		position: absolute;
		left: -120px;
		bottom: 45px;
	}
	.facebook-messenger{
		background: url('img/facebook-chat.png');
		background-size: cover;
		margin: 6px auto 0 auto;
		width: 30px;
		height: 30px;
		display: inline-block;
	}
	.zalo-chat{	
		background: url('img/zalo-chat.png');
		background-size: cover;
		margin: 6px auto 0 auto;
		width: 31px;
		height: 30px;
		display: inline-block;
	}
}
.product_cate_menu{
	margin-top: 12px;
	>.dropdown-toggle{
		color: #FFF;
		span{
			float: left;
			    width: calc(100% - 40px);
				font-size: 12px;
			&.fa{
				font-size: 18px;
				padding: 3px 5px;
				border: 2px solid #FFF;
				margin-left: 10px;
				width: 30px;
				margin-top: 6px;
			}
		}
	}
	>.dropdown-menu{
		min-width: 200px;
		li{
			a{
				text-align: right;
				.fa{
					line-height: 20px;
				}
			}
			&.dropdown{
				>.dropdown-menu{
					right: 100%;
					left: unset;
				}
			}
		}
	}
}