#comment {
	float: left;
	width: 100%;
	margin: 25px 0 0 0;
	padding: 0;
	border-top: 1px solid $divider-color;

	#loading_comment {
		float: left;
		width: 100%;

		.comment {
			list-style: none;
			margin: 10px 0 0 0;
			padding: 0;
			float: left;
			width: 100%;

			> li {
				margin-bottom: 10px;
			}

			li {
				float: left;
				width: 100%;

				p {
					margin-bottom: 0;
				}

				.avatar {
					height: 50px;
					width: 50px;
					background-image: url('img/noavatar.png');
					background-repeat: no-repeat;
					background-size: cover;
					float: left;
					margin-right: 10px;
				}

				.name {
					font-weight: bold;
				}

				.time {
					font-weight: normal;
					color: #888;
				}

				.qtv {
					background: red;
					color: white;
					font-weight: bold;
					padding: 0px 3px;
					margin: 0 5px;
				}
			}
		}

		.child {
			float: left;
			padding: 3px 10px;
			margin: 10px 0px 0 15px;
			width: calc(100% - 15px);
			background: #f1f1f1 none repeat scroll 0 0;
			border: 1px solid #e7e7e7;
			position: relative;

			li {
				list-style: none;
			}

			.arrow-top {
				border-bottom: 15px solid #f1f1f1;
				border-left: 12px solid transparent;
				border-right: 12px solid transparent;
				height: 0;
				margin-top: -15px;
				position: absolute;
				width: 0;
			}
		}

		.paginator {
			float: left;
			width: 100%;
		}
	}

	#write_comment {
		font-weight: bold;
		font-size: 15px;
		text-transform: uppercase;
		padding: 10px 0;
		margin-bottom: 5px;
		float: left;
		width: 100%;
		color: $brand-1-color;
	}

	.product-name {
		color: red;
		margin-bottom: 15px;
		float: left;
		width: 100%;
	}

	#form_comment {
		float: left;
		width: 100%;

		#CommentDescription {
			@media screen and (min-width: $tablet) {
				height: 60px;
			}
		}
	}
}