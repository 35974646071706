.detail_infomation {
	.box_content.read {
		div,
		img {
			@media screen and (max-width: $tablet) {
				max-width: 100%;
				height: auto !important;
			}
		}
	}

	.related {
		ul {
			margin: 0;
			padding: 0 12px;
		}
	}

	#googleMap {
		height: 600px;

		@media screen and (max-width: $tablet) {
			width: 100%;
			height: 300px;
		}
	}
}