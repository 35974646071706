@charset "utf-8";
body {
	line-height: 18px;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
}

h1,h2,h3,h4,h5,h6{
	padding: 0;
	margin: 0;
}

h1 {
	line-height: 30px;
	font-size: 20px;
}

h2 {
	line-height: 30px;
	font-size: 20px;
}

h3 {
	font-size: 15px;
	line-height:23px;
	margin-bottom: 5px;
}

h4,h5,h6 {
	font-size: 14px;
	line-height:22px;
}
.im{
	color: red;
}
img {
	border: 0px;
	outline: none;
}
.des p{
	line-height: 21px;
}

blockquote,q {
	quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
	content: none;
}
#flashMessage {
	margin: 0 auto;
	width: 100%;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 10000;
}
#flashMessage span{
	border-radius: 3px;
	padding-left: 32px;
	display: block;
	width: 470px;
	line-height: 35px;
	margin: 0 auto;
	background: #D31010;
	color: #fff;
	box-shadow: 0 0 3px #aaa;
}
#flashMessage.success span {
	background-image: url('img/success.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

#flashMessage.error span {
	background-image: url('img/error.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

.error-message {
	color: red;
	line-height: 22px;
	float: left;
	text-align: left;
	padding-left: 5px;
}
.form .error-message{
	display: block;
	float: none;
	margin-bottom: 5px;
	padding: 0;
	font-size: 11px;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}
input,textarea,select{
	border-radius: 3px;
	border: 1px solid #D3D3D3;
}
input:focus,textarea:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 4px #E5E5E5;
	overflow: hidden;
}
/* remember to highlight inserts somehow! */
ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

.highlight{
  background-color: yellow;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clear {
	clear: both;
}

.clearL {
	clear: left;
}

.clearR {
	clear: right;
}

/**** #ajax_loading ****/
#message_top {
	display: none;
	top: 20px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;
}
#message_top #loading{
	width: 160px;
	margin: 0 auto;
	padding: 30px 10px 15px;
	border-radius: 3px;
	box-shadow: 0px 0px 4px #333;
	opacity: 0.9;
	background: #fff url(img/ajax-loading.gif) center 10px no-repeat;
}
.icon_oneweb{
	background-image: url('img/icon.png');
}
.float_left {
	float: left;
}
.float_right {
	float: right;
}
.font-weight-bold{
	font-weight: bold;
}
.font-style-italic{
	font-style: italic;
}
.m-t-15{
	margin-top: 15px;
}
.m-b-15{
	margin-bottom: 15px;
}
.m-t-40{
	margin-top: 40px;
}
.m-b-40{
	margin-bottom: 40px;
}
.m-r-15{
	margin-right: 15px;
}
.m-r-10{
	margin-right: 10px;
}
.m-l-15{
	margin-left: 15px;
}
.m-y-15{
	margin-bottom: 15px;
	margin-top: 15px;
}
.m-x-15{
	margin-left: 15px;
	margin-right: 15px;
}
.p-r-15{
	padding-right: 15px;
}
.p-t-15{
	padding-top: 15px;
}
.p-b-15{
	padding-bottom: 15px;
}
.p-y-15{
	padding-bottom: 15px;
	padding-top: 15px;
}
.p-x-15{
	padding-right: 15px;
	padding-left: 15px;
}

/*header */
#header {
    background: #fff;
}
.navbar-header{
}
nav a {
    color: #333;
}
.mm-menu .mm-list > li:after{
	border-color:transparent;
}
.navbar {
    border: 0;
	margin-bottom: 0;
}
.navbar-brand, .navbar-nav>li>a{
	text-shadow: none;
}

#nav_menu .navbar-form{
	margin-top: 3px;
}
/* #nav_menu .nav>li>a:focus, #nav_menu .nav>li>a:hover { */
/*     color: #fff; */
/*     background-color: transparent; */
/* } */
/* #nav_category_list nav>li>a:focus, #nav_category_list .nav>li>a:hover { */
/*     color: #e31b00; */
/*     background-color: transparent; */
/* } */
span.title_text {
    white-space: nowrap;
}
#nav_menu .navbar-form .form-control{
	height: 28px;
	border-radius: 5px;
    padding-right: 25px;
}

.home .ex_border {
    border: 1px solid #ccc;
}
aside#nav_category_list {
	box-shadow: none;
    border-radius: 0;
/*     left: 15px; */
	float: left;
/*     width: 90%; */
	margin-top: 0;
}
div#nav_category {
/*     width: 100%; */
}
aside#nav_category_list ul.nav{
}
#icon_adv .text {
    margin-top: 5px;
    white-space: nowrap;
    display: inline-block;
}

.navbar-default .navbar-toggle .icon-bar{
	background-color: #fff;
}
.navbar-toggle {
    margin-right: 0;
    border-color: #fff;
}
.navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-nav > li > a{
	color: #262726;
	font-weight: bold;
	text-transform: uppercase;
}
.category .icon_li {
    background-image: none;
}
/* .list_post .category .icon_li,.detail_infomation .category .icon_li, .contact .category .icon_li{ */
/*     background-image: url('img/icon.png'); */
/* } */
/* mobile */
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a{
	background-image: none;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #045c2c;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus{
	color: #fff;
	background-color: #045c2c;
}

.nav > li > a:hover, .nav > li > a:focus,.nav > li.active > a {
    text-decoration: none;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background-color: #fff;
}
.navbar-default .navbar-toggle:hover .icon-bar, .navbar-default .navbar-toggle:focus .icon-bar{
	background-color: #045c2c;
}
.affix {
    top: 180px;
}
.box-content {
    display: inline-block;
    width: 100%;
	float: left;
	background-color: #fff;
}
.color_blue{
	color: #0872ba;
}
.color_red{
	color: #ff0000;
}
.content_inner {
}
.scroll_y{
	overflow-x: hidden;
	overflow-y: scroll;
}
ul.social li{
	float: left;
	list-style: none;
}
.search{
	position: relative;
}
#logo {
    background: #fff;
    height: 85px;
    line-height: 85px;
}
ul.link li {
    float: left;
    list-style: none;
    margin:0 8px;
}
#filter_basic{
	height: 25px;
    font-style: italic;
}
.line_height30{
	line-height: 30px;
}
/* detail product */
.tab_content td {
    padding: 5px;
}
p.name.min_height45 {
    margin-bottom: 0;
}
.m-b-10 {
    margin-bottom: 10px;
}

/* fancybox */
/*  Product View */
.product-view .product-img-box {
	margin-bottom: 10px;
	position: relative;
}
.product-view .product-img-box .product-image {
	margin: 0 0 13px;
}
/* Product Images */

/* Product Images */

#etalage .moreview_thumb {
	cursor: move;
}
#moreview .moreview_small_thumbs li {
	margin: 10px;
	margin-bottom: 13px !important;
	cursor: pointer;
}
#moreview .moreview_magnifier {
	border: 1px solid #bbb;
}
#moreview .moreview_hint {
	width: 130px;
	height: 57px;
}
#moreview .moreview_description {
	font-style: italic;
	margin: 10px;
	padding: 6px 10px;
}
.moreview, .moreview_thumb, .moreview_thumb_image, .moreview_source_image, .moreview_zoom_preview, .moreview_icon, .moreview_hint {
	display: none
}
.moreview, .moreview ul, .moreview li, .moreview img, .moreview_hint, .moreview_icon, .moreview_description {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none
}
.moreview, .moreview_magnifier div, .moreview_magnifier div img, .moreview_small_thumbs ul, ul .moreview_small_thumbs li, .moreview_zoom_area div, .moreview_zoom_img {
	position: relative
}
.moreview img, .moreview li {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-moz-user-drag: none;
	user-drag: none
}
.moreview, .moreview_small_thumbs li {
	float: left
}
.moreview_right {
	float: right
}
.moreview li {
	position: absolute
}
.moreview img {
	vertical-align: bottom;
	max-width: none
}
.moreview_magnifier {
	cursor: default
}
.moreview_magnifier div, .moreview_small_thumbs {
	overflow: hidden
}
.moreview_magnifier div img {
	display: none
}
.moreview_icon, .moreview_hint {
	cursor: default;
	width: 0;
	height: 0;
	overflow: hidden
}
.moreview_small_thumbs li.vertical {
	float: none
}
.moreview_zoom_area {
	z-index: 2
}
.moreview_zoom_area div {
	overflow: hidden;
	z-index: 997
}
.moreview_zoom_preview {
	position: absolute;
	z-index: 998
}
.moreview_zoom_img, .moreview_hint {
	z-index: 999
}
.moreview {
	direction: ltr
}
div.moreview_description {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999
}
div.moreview_description.rtl {
	direction: rtl;
	text-align: right
}
.moreview-control a {
	left: auto;
	width: 26px;
	height: 26px;
	background-color: transparent;
}
.product-view .product-img-box .moreview-control {
	opacity: 0;
}
.product-view .product-img-box:hover .moreview-control {
	opacity: 1;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-prev {
	position: absolute;
	left: 9.5%;
	background-color: #fff;
	border: 1px #ddd solid;
	text-align: center;
	color: #333;
}
.moreview-control a.moreview-prev:hover {
	background-color: #333;
	border: 1px #333 solid;
	color: #fff;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-prev:before {
	content: "\f106";
	font-family: FontAwesome;
	font-size: 18px;
}
.moreview-control a.moreview-next {
	position: absolute;
	left: 9.5%;
	background-color: #fff;
	border: 1px #ddd solid;
	text-align: center;
	color: #333;
	bottom: 0%;
}
.moreview-control a.moreview-next:hover {
	background-color: #333;
	border: 1px #333 solid;
	color: #fff;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-next:before {
	content: "\f107";
	font-family: FontAwesome;
	font-size: 18px;
}
.moreview .roll-over {
	position: absolute;
	bottom: 10px;
	left: 10px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	color: #888;
	text-indent: 20px;
}
.moreview .roll-over .roll-over-desc {
	margin-left: 20px;
}
#moreview .moreview_thumb, #moreview .moreview_small_thumb {
	border: solid 1px #e0e0e0;
}
.product-view .product-img-box .product-image {
	margin: 0 0 13px;
}
img.zoomImg {
    display: none!important;
}
/*** zoom popup ***/
.detail_product .product-img-box .zoom{
	position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    width: 100%;
    height: 100%;
}
.product-view .info .border_info{
	float: left;
	width: 100%;
}
.promotion .row {
    margin-left: -30px;
    margin-right: -30px;
    border-top: 1px solid #ccc;
    padding: 10px 0 0;
    color: red;
    line-height: 23px;
}
.btn_call_now {
    background-color: #d73f32;
    background-image: none;
    padding: 6px 0;
    display: inline-block;
    border-radius: 5px;
    font-size: 22px;
    text-shadow: none;
    font-weight: bold;
    line-height: 31px;
    color: #fff;
    max-width: 300px;
    min-width: 200px;
	width: 100%;
    min-height: 46px;
}
.lnk_comment{
	border: 1px solid #ccc;
    padding: 3px 5px;
    border-radius: 4px;
    margin-top: 15px;
}

span.icon_promotion {
	height: 35px;
    width: 40px;
    position: absolute;
    top: -6px;
    right: 43px;
    background-position: -510px -72px;
}
.btn_big_cart {
    line-height: 63px;
    height: 75px;
}
.btn_big_cart:hover, .btn_big_cart:active,.btn_big_cart:focus,.btn_big_cart:active:focus,.btn_call_now:hover,.btn_call_now:focus,.btn_call_now:active{
	background-color: #d73f32;
    background-image: none;
	color: #fff;
    text-decoration: none;
}
/*******end phần sử dung chung*************/
a.btn_cart {
	border-radius: 0;
    border: none;
    background-color: #4471c2;
    background-image: none;
    text-shadow: none;
    color: #fff;
}
a.btn_cart:hover {
    background-color: #32569a;
    background-image: none;
    text-shadow: none;
    color: #fff;
}
.btn_custom{
	background-color: #045c2c;
    color: #fff;
}
.btn_custom:hover{
	background: #00c654;
	color: #fff;
}
#myTabContentProducts{
    float: left;
    width: 100%;
    height: 100%;
}
/* header , menu */
a.logo {
    height: 85px;
    line-height: 85px;
}

.line_nav_top{
	color: #fff;
}
.dropdown .dropdown-menu .dropdown .dropdown-menu{
	top: 0;
	left: 100%;
	>li{
		@media(max-width: 767px){
			padding-left: 30px;
		}
	}
}
.dropdown-menu{
	background-color: $brand_header;
	>li>a{
		color: #FFF;
	}
}
.navbar-nav>li>a:hover{
	background-image: none;
	color: #FFF;
}
.dropdown-menu>li>a:hover{
	background-color: $brand-new-color;
	background-image: none;
	color: #FFF;
}
.box.category.dropdown-menu .nav>li>a {
    padding: 6px 10px;
}

.category_sub .nav>li>a{
	padding: 8px 10px 8px 21px;
}
.box.category .nav>li>a{
	text-transform: uppercase;
	font-size: 13px;
	white-space: nowrap;
}
.name a {
	font-size: 14px;
	font-weight: bold;
}
#show_post_related ul {
    padding-left: 15px;
    float: left;
    width: 100%;
    margin-top: 10px;
}
.box .nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
}

.icon_arrow {
    width: 20px;
    height: 15px;
    margin-top: 10px;
    background-position: -145px -121px;
}
.box_banner img {
    min-height: 120px;
}
.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none;
    color: #fff;
}
/* submenu */
.box.category .nav-v > ul.nav > li >ul.nav > li{
	border-bottom: 1px solid #eee;
    position: relative;
    padding-right: 20px;
}
a.clk_dropdownmenu {
    float: right;
}
.box.category .nav-v > ul.nav > li >ul.nav > li .icon_li{
	width: 30px;
	margin-top: 0;
    height: 27px;
}
.box.category .nav-v > ul.nav > li >ul.nav > li:hover .icon_li{
	background:url(img/arrow_red.png) no-repeat 2px 1px;
	transition: 0.3s;
}
.box.category .nav-v > ul.nav > li >ul.nav > li a {
    text-transform: none;
    z-index: 9999;
    position: relative;
}

.box.category ul.nav > li > ul {
    position: absolute;
    left: 100%;
    z-index: 2;
    top: 0;
	display: none;
    width: auto;
    background: #fff;
}

.nav .open>a,.nav .open>a:hover{
	background-color: $brand-new-color;
}
.nav-v > ul.nav > li.select > ul > li.select > a:hover{
	background-color: transparent;
	color: #e31b00;
}
.box.category ul.nav li:hover ul{
	display: block;
}
.top_social a.icon_oneweb {
    width: 16px;
    height: 34px;
    margin-right: 3px;
}
.icon_shopping_cart{
	 width: 20px;
    height: 19px;
    float: none;
    display: inline-block;
    background-position: -382px -143px;
}
.btn-cart {
    background-color: #ed1c24;
    background-image: none;
    text-shadow: none;
    color: #fff;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
}
.btn-cart:hover{
	 background-color: #04b100;
    background-image: none;
    text-shadow: none;
    color: #fff;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
}
.item_product_thumb {
    position: relative;
	overflow: hidden;
	border: 1px solid #ddd;
}
.item_product_thumb img{
	display: inherit;
}
.item_product_thumb:hover .item_hover{
	background: url(img/hover.png) repeat top center;
}
.item_product_thumb:hover .button_cart{
	bottom: 5px;
}
.item_hover {
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
	left: 0;
}
.button_cart {
    transition: 0.5s;
    position: absolute;
    bottom: -35px;
}
.color-red{
	color:#e20202;
}
span.number_product_cart {
    background-color: $brand-b-color;
    padding: 1px 5px;
    border-radius: 3px;
    color: #FFF;
    font-weight: bold;
}
.icon_facebook{
	background-position: -332px -110px;
}
.icon_google{
	background-position: -358px -110px;
}
.icon_twitter{
	background-position: -385px -110px;
}
div#icon_adv div span.icon_oneweb {
    width: 40px;
    height: 40px;
}
.benefit span{
	font-size: 11px;
	float: right;
    white-space: nowrap;
}
.icon_bestprice{
	background-position: -249px 0;
}
.icon_iso {
    background-position: -327px 0;
}
.icon_freeship {
    background-position: -409px 0;
}
#icon_adv div {
    min-height: 34px;
}

.bg_title h2 {
    padding: 0 10px;
    white-space: nowrap;
}
.bg_title h2 a{
	font-size: 15px;
	color: #fff;
}
.border_right {
    border-right: 1px solid #eee;
}
.product_run p.name {
    height: 40px;
    margin-top: 7px;
}
.item a.name {
    display: inline-block;
    color: #333;
    font-weight: bold;
}

.info table td, .info table th{
	padding: 3px 3px 3px 0;
}
.info .price span.new {
    font-size: 25px;
}
.list_post .post a.name {
    font-size: 17px;
}

.nav-v-post ul li ul li a {
    color: #333;
    font-weight: normal;
}
.m-t-12 {
    margin-top: 12px;
}
.title_adv{
	position: absolute;
    background-color: rgba(8,114,186,0.55);
    bottom: 10px;
    height: 30px;
    line-height: 30px;
    color: #d12d27;
    width: 97%;
	font-size: 20px;
	text-shadow: 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff, 0 0 4px #fff;
}
span.bg_title {
    display: inline-block;
    padding: 0 15px;
}
.bg_white{
	background-color: #fff;
}
.bg_gray{
	background-color: #f5f5f5;
}

.sub_category a {
	color: #0872ba;
    font-size: 13px;
     height: 30px;
    line-height: 10px;
    white-space: nowrap;
}
span.discount {
	background-color: #04b100;
    color: #fff;
    padding: 4px;
    font-size: 13px;
    float: left;
    z-index: 1;
    position: absolute;
    font-weight: bold;
    right: 0;
}

/* trang danh mục*/
ul.sort {
    list-style: none;
    position: absolute;
   	top: 6px;
    right: 15px;
    padding: 0;
    margin: 0;
}

.shoping_cart .icon_delete {
    width: 17px;
    height: 17px;
    background-position: -9px -98px;
    display: block;
}
.shoping_cart .icon_delete:hover{
	background-position: -38px -98px;
}
/* end shoping cart */
.customNavigation .btn {
    position: absolute;
    top: 40%;
}
.customNavigation .prev {
	left: 22px;
}
.customNavigation .next{
	right: 22px;
}
.customNavigation .btn{
	background-color: #e2e2e2;
    top: 39%;
    width: 21px;
    padding: 0 3px;
    height: 23px;
    text-align: center;
    line-height: 23px;
    color: #fff;
}
#owl-product .item{
	display: inline-block;
	padding: 10px 0;
}
.min_height45 {
    min-height: 45px;
    height: 47px;
}
.price_old{
	color: #999;
}
ul.social{
	margin-top: 15px;
    padding: 0;
    float: left;
}
.adv ul {
    list-style: none;
    padding: 0;
}
ul#adv_right_out {
    position: fixed;
    top: 28%;
	z-index: 3;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
}
ul#adv_left_out {
    position: fixed;
    top: 28%;
    left: 0;
    list-style: none;
    padding: 0;
    margin: 0;
}
.block-title {
    height: 40px;
    /* background-color: #0871b8; */
    line-height: 40px;
    font-weight: bold;
    font-size: 17px;
    color: #0871b8;
    padding: 0 15px;
    border-bottom: 2px solid #0871b8;
}
.home_box_post .item{
	border-right: none;
	min-height: auto;
}
.home_box_post .thumb img {
    border: 1px solid #ccc;
    padding: 2px;
    margin-bottom: 7px;
}
.owl-carousel .owl-item {
    width: 228px;
}
.price span {
    font-size: 15px;
}
/* footer */
#footer address table td{
    line-height: 23px;
	font-size: 13px;
}
#footer address a{
	color: #fff;
}
.text_link address p {
    line-height: 27px;
    font-size: 19px;
}
.call_now {
    position: fixed;
    z-index: 10;
    right: 0px;
    bottom: 277px;
}
address table ul{
	padding-left: 15px;
}
address table ul li {
    list-style-image: url(img/f_li.png);
}
.copyright p{
	color: #fff;
	float: left;
	height: 30px;
	line-height: 30px;
}
#popup-banner{
	.modal-content{
		border: 0;
		.modal-body{
			padding: 0;
		}
	}
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
aside#nav_category_list {
    left: 15px;
    width: 90%;
}
	#nav_menu{
		height: 40px;
	}
	.m-t-20 {
	    margin-top: 20px;
	}
	.col-xs-20{
		width: 20%;
	}
	.navbar-brand {
	    width: 100%;
		height: 39px;
	}
	.input#srch-term{
		width: 406px;
	}
	.home #nav_category_list{
	display: none;
	}
	.col-lg-set2{
		width: 13%;
	}
	.col-lg-set4{
		width: 36%;
	}
	.col-lg-less-7{
		width: 54%;
	}
	.col-lg-more-5{
		width: 46%;
	}
	.clk_dropdownmenu{
		display: none!important;
	}
	.box.category ul.nav > li.select > ul{
		display:block!important;
	}
	.navbar-nav>li>a {
		padding-top: 5px;
		padding-bottom: 5px;
		color: #fff;
	}
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.nav>li>a{
		margin: 0;
	}
	.navbar-nav{
		float: left;
	}
	.navbar-header {
	    float: left;
	    width: 258px;
	}
	.navbar-nav>li>a {
		padding-top: 5px;
		padding-bottom: 5px;
		color: #fff;
	}
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
	#adv_right_out{
		display:none;
	}
	.product_des img,.des img{
		max-width: 100%;
	}
	.nav>li>a {
		padding: 10px;
	}
}
.navbar .navbar-nav>li>a{
	font-size: 14px;
}
@media (min-width: 768px){
	.p-r-0{
		padding-right: 0;
	}
	.p-l-0{
		padding-left: 0;
	}
	.p-x-0{
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-btn{
		width: 1%!important;
	}
	.submenu-caret-wrapper{
		display: none;
	}
	.navbar {
		min-height: 30px;
		display: none;
	}
	.navbar-collapse{
		padding: 0;
	}
	.hotline-mobile{
		display: none;
	}
	.modal-dialog {
		width: 750px;
		margin-top: 125px;
	}
	
a.clk_dropdownmenu {
	    float: right;
	    position: absolute!important;
	    top: 0;
	    padding: 6px !important;
	    right: 10px;
	}
	.box.category ul.nav > li.select > ul{
		display:none;
	}
	.box.category ul.nav > li.select.submenu > ul.sub_menu {
		position: static;
	    display: block;
	}
.box.category.dropdown-menu .nav>li>a {
    padding: 6px 10px;
}
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	.nav>li>a {
		color: #FFF;
	}
	.p-r-m-0{
		padding-right: 0!important;
	}
	.navbar-nav{
		width: 100%;
		.submenu-caret-wrapper{
			float: right;
		}
	}
	.navbar {
		min-height: 0px;
	}
	#adv_right_out{
		display:none;
	}
	.box.category .nav>li>a{
	color: #fff;
	}
	#nav_category_list li{
		border-bottom: 1px solid #87b9ef;
	}
	.nav.mm-list .icon_li {
	    display: none;
	}
	address td {
	    float: left;
	    width: 100%;
	}
	.box.category.dropdown-menu .nav>li>a {
	    padding: 8px 10px;
	}

	form#form-filter-basic {
	    padding-top: 0;
	    padding-bottom: 0;
	}
	#nav_menu .navbar-form {
	    margin-right: 0;
	}
	.lf-ghost{
		display: none!important;
	}
	#icon_adv div {
	    margin: 0;
	}
	.modal-dialog {
		margin-top: 145px!important;
		margin-bottom: 55px!important;
	}
	.product_des img,.des img{
		max-width: 100%;
		height: auto!important;
	}
	aside#nav_category_list{
/* 		width: 95%; */
/* 		z-index: 99; */
	}
	.box.category ul.nav > li.select > ul{
		display:none;
	}
	.box.category ul.nav > li.select.submenu > ul.sub_menu {
		position: initial;
	    display: block;
	    z-index: 999;
	}
	a.clk_dropdownmenu {
	    float: right;
	    position: absolute!important;
	    top: 0;
	    padding: 6px !important;
	    right: 10px;
	}
	.icon_arrow_down{
	}
	.current .icon_arrow_down{
		color: #fff;
	}
}

/* Portrait phones and smaller */
@media (max-width: 480px) {


}
@media (max-width: 425px) {

}
@media (max-width: 375px){

}
@media (max-width: 320px){
	.top_social{
		width: 100%;
	}
	nav.right {
	    width: 100%;
	}
}
h1,h2{
	.title{
		text-transform: uppercase;
		span{
			font-weight: bold;
			padding: 5px 0;
			color: $brand-b-color;
			border-bottom: 2px solid $brand-b-color;
		}
	}
}